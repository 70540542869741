import { DefaultButton, Label, Panel, PanelType, PrimaryButton, Stack, Text } from "@fluentui/react";
import { Person } from '..//types';
import { JobSeekerField } from "./JobSeekerField";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";

type JobSeekerDetailsProps = {
    person: Person
    onDismiss: () => void
}

const buttonStyles = { root: { marginRight: 8 } };

export function JobSeekerDetails(props: JobSeekerDetailsProps) {
    const { person, onDismiss } = props

    const printContents = useRef()
    const onPrint = useReactToPrint({
        content: () => printContents.current,
        copyStyles: true,
    })

    return <Panel
        styles={{ footer: { backgroundColor: "#fff" } }}
        headerText="Jobseeker Details"
        isOpen={true}
        type={PanelType.medium}
        onDismiss={onDismiss}
        closeButtonAriaLabel="Close"
        onRenderFooterContent={() => (
            <div>
                <PrimaryButton onClick={onPrint} styles={buttonStyles}>Print</PrimaryButton>
                <DefaultButton onClick={onDismiss}>Close</DefaultButton>
            </div>
        )}
        isFooterAtBottom={true}
    >
        <div className="print-panel" ref={printContents}>
            <Stack styles={{ root: { paddingTop: 0 } }} tokens={{ childrenGap: 0 }}>
                <Text variant="xLargePlus" styles={{ root: { color: "#004990", fontSize: 40, fontWeight: 500 } }}><JobSeekerField person={person} property={"fullName"} /></Text>
                <Text variant="mediumPlus" styles={{ root: { color: "#004990", fontSize: 16, fontWeight: 500, marginTop: -12 } }}><JobSeekerField person={person} property={"expertise"} as="span" /> - <JobSeekerField person={person} property={"location"} as="span" /></Text>
                <Stack>
                    <h3>Contact information</h3>
                    <Label className="section-label">{getPersonFieldLabel("email")}</Label>
                    <JobSeekerField person={person} property={"email"} />
                    <Label className="section-label">{getPersonFieldLabel("phone")}</Label>
                    <JobSeekerField person={person} property={"phone"} />
                    {person.linkedInProfile && (
                        <>
                            <Label className="section-label">{getPersonFieldLabel("linkedInProfile")}</Label>
                            <JobSeekerField person={person} property={"linkedInProfile"} />
                        </>
                    )}
                </Stack>
                <Stack>
                    <h3>Experience</h3>
                    <Label className="section-label">{getPersonFieldLabel("expertise")}</Label>
                    <JobSeekerField person={person} property={"expertise"} />
                    {"Other" === person.expertise && <>
                        <JobSeekerField person={person} property={"otherExpertise"} />
                    </>}
                    <Label className="section-label">{getPersonFieldLabel("experience")}</Label>
                    <JobSeekerField person={person} property={"experience"} />
                    {person.workHistory && person.workHistory.length > 0 && (
                        <>
                            <Label className="section-label">{getPersonFieldLabel("workHistory")}</Label>
                            <JobSeekerField person={person} property={"workHistory"} />
                        </>
                    )}
                    {person.credentials && (
                        <>
                            <JobSeekerField person={person} property={"credentials"} />
                        </>
                    )}
                </Stack>
            </Stack>
        </div>
    </Panel>
}

function getPersonFieldLabel(property: keyof Person) {
    switch (property) {
        case "fullName": {
            return "Name"
        }
        case "firstName": {
            return "First name"
        }
        case "lastName": {
            return "Last name"
        }
        case "email": {
            return "Email"
        }
        case "credentials": {
            return "Credentials"
        }
        case "experience": {
            return "Years of experience in work area"
        }
        case "expertise": {
            return "Area of work expertise"
        }
        case "otherExpertise": {
            return ""
        }
        case "phone": {
            return "Phone"
        }
        case "linkedInProfile": {
            return "Linked In"
        }
        case "location": {
            return "Preferred work location(s)"
        }
        case "workHistory": {
            return "Work history"
        }
    }
}