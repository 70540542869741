import { Stack, TextField, Text, PrimaryButton, DefaultButton, ProgressIndicator, ActionButton, compareDatePart, Checkbox, Link } from "@fluentui/react";
import { useSavedState } from "../logic";
import { PropsWithChildren, useEffect, useState } from "react";
import { getTheme } from '@fluentui/react';

const theme = getTheme();

const login = (memberId: string, name: string, email: string): Promise<string> => {
    return fetch("/api/login", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            memberId: memberId,
            name: name,
            email: email
        })
    })
        .then(r => r.json())
        .then(d => d.company)
        .catch(err => console.log("Error logging in.", err))
}

const NumberPattern = new RegExp("^\\d*$")

const EmailPattern = new RegExp("^(([^<>()[\\]\\\\.,;:\\s@\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$")

const isDefined = (...errors: string[]): boolean => {
    return Array.isArray(errors) && errors.some(error => {
        return typeof error === "string" && error.trim().length > 0
    })
}

type LoginInfo = {
    memberId: string
    companyName: string
}

type LoginProps = {
    onLogin: (company: string) => void
    logOut: (onLogout: () => void) => void
}

export function Login(props: PropsWithChildren<LoginProps>) {
    const [info, setInfo] = useSavedState<LoginInfo | undefined>("login")
    const [hasEntered, setHasEntered] = useSavedState<string>("confirmed")

    const [memberId, setMemberId] = useState('')
    const [memberIdError, setMemberIdError] = useState('')
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('')
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [checkboxSelected, setCheckboxSelected] = useState(false)

    const onLoginClicked = () => {
        login(memberId.trim(), name.trim(), email.trim())
            .then(company => {
                setInfo({
                    memberId: memberId,
                    companyName: company
                })
            })
    }

    const getErrorMessage = (field: "Member ID" | "Full Name" | "Email", value: string): string => {
        let errorMessage: string = ''
        const testValue = value?.trim() || ''
        if (testValue.length > 0) {
            if (field === "Full Name" && !testValue.includes(" ")) {
                errorMessage = 'Enter first and last name.'
            }
            if (field === "Email" && !EmailPattern.test(testValue)) {
                errorMessage = 'Enter a valid email address.'
            }
            if (field === "Member ID" && !NumberPattern.test(testValue)) {
                errorMessage = 'Enter a valid member id.'
            }
        }
        else {
            errorMessage = `${field} is required.`
        }
        return errorMessage
    }

    const onEnterClicked = () => {
        const now = new Date()
        now.setDate(now.getDate() + 1)
        setHasEntered(now.toISOString())
        props.onLogin(info.companyName)
    }

    const onLeaveClicked = () => {
        setInfo(undefined)
        setHasEntered('')
        setMemberId('')
        setCheckboxSelected(false)
    }


    useEffect(() => {
        props.onLogin(info?.companyName || '')
    }, [info])

    props.logOut(onLeaveClicked)

    if (!info) {
        return <Stack tokens={{ childrenGap: 16, padding: 24 }} horizontalAlign="center" style={{ boxShadow: theme.effects.elevation8, width: "50%" }}>
            <Text>
                <p>This database contains Yellow employee profile information and is accessible to ATA Members only.</p>
                <p>If you are a former yellow employee looking for the job seeker submission form, click here: <Link href="https://www.trucking.org/jobseeker">www.trucking.org/jobseeker</Link>.</p>
                <p style={{ textAlign: "center" }}>Please enter your ATA Member Company ID:</p>
            </Text>
            <TextField
                required
                maxLength={8}
                inputMode="numeric"
                label="Member ID"
                errorMessage={memberIdError}
                onBlur={() => setMemberIdError(getErrorMessage("Member ID", memberId))}
                onChange={(_, v) => setMemberId(v)}
                styles={{ root: { maxWidth: 250 } }} />
            <TextField
                required
                label="Full Name"
                title="Enter your first name and last name"
                inputMode="text"
                errorMessage={nameError}
                onBlur={() => setNameError(getErrorMessage("Full Name", name))}
                onChange={(_, v) => setName(v)}
                styles={{ root: { maxWidth: 250 } }} />
            <TextField
                required
                label="Email"
                title="Enter your company email address"
                inputMode="email"
                errorMessage={emailError}
                onBlur={() => setEmailError(getErrorMessage("Email", email))}
                onChange={(_, v) => setEmail(v)}
                styles={{ root: { maxWidth: 250 } }} />
            <Stack horizontal>
                <PrimaryButton text="Login" disabled={isDefined(memberIdError, nameError, emailError)} onClick={onLoginClicked} />
            </Stack>
        </Stack>
    }

    if (!info.companyName) {
        return <Stack tokens={{ childrenGap: 16, padding: 24 }} horizontalAlign="center" style={{ boxShadow: theme.effects.elevation8, width: "50%" }}>
            <Text>Unable to find an active subscription for the current member id. Click below to request access.</Text>
            <Stack horizontal tokens={{ childrenGap: 8 }}>
                <PrimaryButton text="Request access" data-interceptor="off" target="_blank" href="mailto:atamembership@trucking.org?subject=Access%20To%20ATA%20Job%20Seeker%20Database" />
                <DefaultButton text="Go back" onClick={onLeaveClicked} />
            </Stack>
        </Stack>
    }

    if (!hasEntered || new Date(hasEntered) < new Date()) {
        return <Stack tokens={{ childrenGap: 16, padding: 24 }} horizontalAlign="center" style={{ boxShadow: theme.effects.elevation8, width: "50%" }}>
            <Text>You are logging into the job seeker database on behalf of <strong>{info.companyName}</strong>.</Text>
            <Text>By accessing the ATA Jobseeker Database (the Database), you agree that you have read and acknowledge the terms of the American Trucking Associations (ATA) Privacy Policy, available at <Link href="https://trucking.org/privacy-policy">https://trucking.org/privacy-policy</Link>, and that you will use any information collected from the Database for the sole and exclusive purpose of identifying and contacting potential candidates for jobs at your company or its subsidiaries or affiliates. You further agree not to sell or share with any third party any information obtained from the Database, and to delete and destroy any and all copies of such information within 30 days of obtaining it.</Text>
            <Checkbox label="I agree to the above and the terms & conditions." onChange={(_, checked) => setCheckboxSelected(checked)} />
            <Stack horizontal tokens={{ childrenGap: 8 }}>
                <PrimaryButton text="Continue" disabled={!checkboxSelected} onClick={onEnterClicked} />
                <DefaultButton text="Cancel" onClick={onLeaveClicked} />
            </Stack>
        </Stack>
    }

    return <>
        {props.children}
    </>
}