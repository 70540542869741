import * as React from "react"

const getCookie = (key: string): string => {
    const cookies = document.cookie.split(";").map(x => x.trim())
    for (var cookie of cookies) {
        const cookieParts = cookie.split("=")
        if (cookieParts[0] === key) {
            return cookieParts[1]
        }
    }
    return ''
}

export const useSavedState = <T extends unknown>(key: string, defaultValue?: (() => T) | T): [T, (value: T) => void] => {
    const [value, setValue] = React.useState<T>(defaultValue || '' as T)

    const storeValue = React.useMemo(() => {
        return (newValue: T) => {
            try {
                const textValue = newValue ? typeof newValue !== "string" ? JSON.stringify(newValue) : newValue : ''
                document.cookie = `${key}=${btoa(textValue)}`
            }
            catch (err) {
                console.log(`Error writing the cookie '${key}'.`, err)
            }
            setValue(newValue as T)
        }
    }, [key])

    React.useEffect(() => {
        try {
            let value: T
            const text = atob(getCookie(key) || '')
            try {
                value = JSON.parse(text)
            }
            catch {
                value = text as T
            }
            setValue(value)
        }
        catch (err) {
            console.log(`Error reading the cookie '${key}'.`, err)
        }
    }, [key])

    return [value, storeValue]
}