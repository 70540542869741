import { ColumnActionsMode, CommandBar, ConstrainMode, ContextualMenu, DetailsList, DetailsListLayoutMode, DirectionalHint, IColumn, IContextualMenuItem, MessageBar, MessageBarType, ProgressIndicator, SearchBox, SelectionMode, Stack, Text } from "@fluentui/react";
import { useEffect, useRef, useState } from "react";
import { JobSeekerDetails } from "./JobSeekerDetails";
import { Person } from '../types';
import { JobSeekerField } from "./JobSeekerField";

const getPeople = (): Promise<Person[]> => {
    return fetch(`/api/people?t=${Date.now()}`)
        .then(r => r.json())
        .catch(err => console.log("Error logging in.", err))
}

type JobSeekerColumn = Omit<IColumn, 'key'> & {
    key: keyof Person
}

const buildColumn = (name: string, field: keyof Person): JobSeekerColumn => {
    return {
        key: field,
        minWidth: 50,
        name: name,
        fieldName: field,
        isResizable: true,
        flexGrow: 1,
        isSorted: false,
        isSortedDescending: false,
        onRender: (person: Person) => {
            return <JobSeekerField person={person} property={field} />
        }
    }
}

const buildFilterOption = (name: string, checked: boolean, onClick: () => void): IContextualMenuItem => {
    return {
        key: name,
        text: name,
        canCheck: true,
        checked: checked,
        onClick: onClick
    }
}

const JobSeekerColumns: JobSeekerColumn[] = [
    buildColumn("Name", "fullName"),
    buildColumn("Area of Expertise", "expertise"),
    buildColumn("Years of Experience", "experience"),
    buildColumn("Preferred Location", "location")
]

const getFilteredPeople = (items: Person[], searchText: string, filters: Filters): Person[] => {
    let results = items.slice()

    if (searchText) {
        const searchTextLower = searchText.toLocaleLowerCase()
        results = results.filter(person => {
            return person.firstName.toLocaleLowerCase().includes(searchTextLower)
                || person.lastName.toLocaleLowerCase().includes(searchTextLower)
                || person.fullName.toLocaleLowerCase().includes(searchTextLower)
                || person.email.toLocaleLowerCase().includes(searchTextLower)
                || person.expertise.toLocaleLowerCase().includes(searchTextLower)
                || person.location.some(l => {
                    return l.toLocaleLowerCase().includes(searchTextLower)
                })
                || person.workHistory.some(j => {
                    return j.employer.toLocaleLowerCase().includes(searchTextLower)
                        || j.position.toLocaleLowerCase().includes(searchTextLower)
                })
        })
    }

    if (filters.expertise.length > 0) {
        results = results.filter(person => {
            return filters.expertise.some(expertise => {
                return person.expertise === expertise
            })
        })
    }

    if (filters.location.length > 0) {
        results = results.filter(person => {
            return filters.location.some(location => {
                return person.location.some(l => l === location)
            })
        })
    }

    if (filters.experience.length > 0) {
        results = results.filter(person => {
            const experienceNumber = Number(person.experience)
            return !isNaN(experienceNumber) && filters.experience.some(experience => {
                return experience
                    && (typeof experience.low === "undefined" || experienceNumber >= experience.low)
                    && (typeof experience.high === "undefined" || experienceNumber <= experience.high)
            })
        })
    }

    return results.filter(r => r.fullName)
}

type RangeFilter = {
    key: string
    low?: number
    high?: number
}

type Filters = {
    expertise: string[]
    location: string[]
    experience: RangeFilter[]
}

const ExperienceOptions: { [x: string]: { low?: number, high?: number } } = {
    "< 3 Years": {
        high: 3
    },
    "3 - 5 Years": {
        low: 3,
        high: 5
    },
    "5 - 7 Years": {
        low: 5,
        high: 7
    },
    "7 - 10 Years": {
        low: 7,
        high: 10
    },
    "10 + Years": {
        low: 10
    }
}

const FilterColumns: (keyof Person)[] = ["expertise", "experience", "location"]

type JobSeekersProps = {
    company: string
    onSignOut: () => void
}

export function JobSeekers(props: JobSeekersProps) {
    const { company, onSignOut } = props

    const [isMessageBarVisible, setIsMessageBarVisible] = useState(true);
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [filteredItems, setFilteredItems] = useState<Person[]>([])
    const [selectedItem, setSelectedItem] = useState<Person>()
    const [columnMenuItems, setColumnMenuItems] = useState<IContextualMenuItem[]>([])
    const [filters, setFilters] = useState<Filters>({
        experience: [],
        expertise: [],
        location: []
    })

    const contextMenuTarget = useRef<HTMLElement>()
    const items = useRef<Person[]>([])
    const columns = useRef<JobSeekerColumn[]>(JobSeekerColumns.map(c => {
        const column = { ...c }
        if (FilterColumns.includes(c.key)) {
            column.columnActionsMode = ColumnActionsMode.hasDropdown
        }
        return column
    }))

    const getFilterOptions = (column: JobSeekerColumn): IContextualMenuItem[] => {
        const filterOptions: IContextualMenuItem[] = []
        switch (column.key) {
            case "expertise": {
                const expertiseOptions: { [x: string]: boolean } = {}
                items.current.forEach(person => {
                    if (person.expertise?.trim()?.length > 0 && !expertiseOptions[person.expertise]) {
                        expertiseOptions[person.expertise] = true
                        filterOptions.push(buildFilterOption(person.expertise, filters.expertise.includes(person.expertise), () => {
                            const newExpertises = filters.expertise.slice()
                            const expertiseIndex = newExpertises.indexOf(person.expertise)
                            if (expertiseIndex >= 0) {
                                newExpertises.splice(expertiseIndex, 1)
                            }
                            else {
                                newExpertises.push(person.expertise)
                            }
                            setFilters({
                                experience: filters.experience,
                                location: filters.location,
                                expertise: newExpertises
                            })
                        }))
                    }
                })
                filterOptions.sort((a, b) => a.key < b.key ? -1 : 1)
                break;
            }
            case "location": {
                const locationOptions: { [x: string]: boolean } = {}
                items.current.forEach(person => {
                    (person.location || []).forEach(l => {
                        if (l?.trim()?.length > 0 && !locationOptions[l]) {
                            locationOptions[l] = true
                            filterOptions.push(buildFilterOption(l, filters.location.includes(l), () => {
                                const newLocations = filters.location.slice()
                                const locationIndex = newLocations.indexOf(l)
                                if (locationIndex >= 0) {
                                    newLocations.splice(locationIndex, 1)
                                }
                                else {
                                    newLocations.push(l)
                                }
                                setFilters({
                                    experience: filters.experience,
                                    expertise: filters.expertise,
                                    location: newLocations
                                })
                            }))
                        }
                    })
                })
                filterOptions.sort((a, b) => a.key < b.key ? -1 : 1)
                break;
            }
            case "experience": {
                Object.keys(ExperienceOptions).forEach(key => {
                    filterOptions.push(buildFilterOption(key, filters.experience.some(r => r.key === key), () => {
                        const newExperience = filters.experience.slice()
                        const experienceIndex = newExperience.findIndex(x => x.key === key)
                        if (experienceIndex >= 0) {
                            newExperience.splice(experienceIndex, 1)
                        }
                        else {
                            newExperience.push({
                                key: key,
                                low: ExperienceOptions[key].low,
                                high: ExperienceOptions[key].high
                            })
                        }
                        setFilters({
                            expertise: filters.expertise,
                            location: filters.location,
                            experience: newExperience
                        })
                    }))
                })
                break;
            }
        }
        return filterOptions
    }

    const onColumnHeaderClick = (ev?: React.MouseEvent<HTMLElement>, column?: JobSeekerColumn): void => {
        if (column && column.columnActionsMode === ColumnActionsMode.hasDropdown) {
            const filterOptions = getFilterOptions(column)
            if (filterOptions.length > 0) {
                contextMenuTarget.current = ev?.currentTarget
                setColumnMenuItems(filterOptions)
            }
        }
    }

    const onDismissContextMenu = (): void => {
        setColumnMenuItems([])
    }

    const onRenderRow = (props, defaultRenderer) => {
        return <div style={{ cursor: "pointer" }} onClick={() => setSelectedItem(props.item)}>
            {defaultRenderer(props)}
        </div>
    }

    const refreshPeople = () => {
        setError(false)
        setLoading(true)
        getPeople()
            .then(data => {
                items.current = data
                setFilteredItems(getFilteredPeople(data, searchText, filters))
            })
            .catch(err => {
                console.log("Error loading job seekers.", err)
                setError(true)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        refreshPeople()
    }, [])

    useEffect(() => {
        setFilteredItems(getFilteredPeople(items.current, searchText, filters))
    }, [searchText, filters.experience, , filters.expertise, filters.location])

    const handleDismiss = () => {
        setIsMessageBarVisible(false);
    };

    return <Stack className="content-width" tokens={{ padding: "12px 24px", childrenGap: 12 }} >
        {error
            ? <MessageBar messageBarType={MessageBarType.error} >Failed to load job seekers. Please contact support.</MessageBar>
            : <>
                {loading
                    ? <ProgressIndicator label="Loading job seekers..." />
                    : <>
                        {items.current.length > 0 && <>
                            {isMessageBarVisible && (<Stack>
                                <MessageBar messageBarType={MessageBarType.info} onDismiss={handleDismiss} dismissButtonAriaLabel="Close" truncated={true}>
                                    <p>Instructions:</p>
                                    <ol type="1">
                                        <li>Filter data by selecting column headers. You can select more than one option. To clear filter, select the option again.</li>
                                        <li>Use Global Search to search and filter all profile data including data not shown in columns.</li>
                                        <li>Select a profile row to see full profile data and print to PDF to save locally or printer.</li>
                                        <li>Select Close in pane to resume search.</li>
                                    </ol>
                                </MessageBar>
                            </Stack>)}
                            <Stack horizontal horizontalAlign="space-between">
                                <SearchBox
                                    underlined={true}
                                    showIcon
                                    placeholder={"Global search"}
                                    onChange={(_, newValue) => {
                                        setSearchText(newValue)
                                    }}
                                />
                                <CommandBar
                                    items={[
                                        {
                                            key: "menu",
                                            text: `Logged in as ${company}`,
                                            subMenuProps: {
                                                items: [
                                                    {
                                                        key: "signout",
                                                        text: "Sign Out",
                                                        onClick: onSignOut
                                                    }
                                                ]
                                            }
                                        }
                                    ]}
                                />
                            </Stack>
                            <DetailsList
                                items={filteredItems}
                                columns={columns.current}
                                onRenderRow={onRenderRow}
                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                constrainMode={ConstrainMode.unconstrained}
                                selectionMode={SelectionMode.none}
                                onColumnHeaderClick={onColumnHeaderClick}
                            />
                            {selectedItem && <JobSeekerDetails
                                person={selectedItem}
                                onDismiss={() => setSelectedItem(undefined)}
                            />}
                            {columnMenuItems.length > 0 && <ContextualMenu
                                items={columnMenuItems}
                                alignTargetEdge
                                directionalHint={DirectionalHint.bottomLeftEdge}
                                useTargetWidth
                                target={contextMenuTarget.current}
                                onDismiss={onDismissContextMenu}
                                styles={{ list: { maxHeight: 400 } }}
                            />}
                            {filteredItems.length === 0 && <Text variant="large">No people to show.</Text>}
                        </>}
                    </>}
            </>}
    </Stack>
}