import * as React from 'react' ;

type ErrorBoundaryProps = {}
type ErrorBoundaryState = {
    error:string
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props) {
        super(props);
        this.state = { error: "" };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { error: error.toString() };
    }

    public render() {
        if (this.state.error) {
            // You can render any custom fallback UI
            return <pre>{this.state.error}</pre>;
        }

        return this.props.children;
    }
}