import { Stack, initializeIcons } from '@fluentui/react'
import { useRef, useState } from 'react'
import { JobSeekers, Login } from '.'
import { ErrorBoundary } from './ErrorBoundary'

initializeIcons()

export function App() {
    const [company, setCompany] = useState('')

    const onSignOutClicked = () => {
        setCompany('')
        if (logOutHandle.current) {
            logOutHandle.current()
        }
    }

    const logOutHandle = useRef<CallableFunction>()

    return <ErrorBoundary>
    <Stack className='root' horizontalAlign='center'>
        <Login
            onLogin={(c: string) => {
                setCompany(c)
            }}
            logOut={(onLogout) => {
                logOutHandle.current = onLogout
            }}>
            <JobSeekers company={company} onSignOut={onSignOutClicked} />
        </Login>
    </Stack>
    </ErrorBoundary>
}