import { Link, TextField, Text, Stack } from "@fluentui/react"
import { Person } from "../types"

const DateFormatter = new Intl.DateTimeFormat(navigator.language, {
    month: "short",
    year: "numeric",
})

const formatDate = (date?: string) => {
    let value = ""
    if (date) {
        try {
            const match = /^(\d{2})-(\d{2})-(\d{4})$/.exec(date)
            const month = Number(match && match[1])
            const day = Number(match && match[2])
            const year = Number(match && match[3])
            if (!isNaN(month) && !isNaN(day) && !isNaN(year)) {
                value = DateFormatter.format(new Date(year, month-1, day))
            }
        }
        catch { }
    }
    return value
}

type JobSeekerFieldProps = {
    person: Person
    property: keyof Person
    as?: "div" | "span"
}

export function JobSeekerField(props: JobSeekerFieldProps) {
    const { person, property, as } = props

    const Type = as ?? "div"

    switch (property) {
        case "fullName":
        case "firstName":
        case "lastName":
        case "experience":
        case "expertise":
        case "otherExpertise": {
            return <Type>{person[property]}</Type>
        }
        case "credentials": {
            return <Type>
                {person[property] && <TextField label="Credentials" styles={{ field: { padding: 0, overflow: "visible" } }} multiline resizable={false} borderless readOnly autoAdjustHeight value={person[property]} />}
            </Type>
        }
        case "email": {
            return <Type>
                {person[property] && <Link target="_blank" data-interception="off" href={`mailto:${person[property]}`}>{person[property]}</Link>}
            </Type>
        }
        case "phone": {
            return <Type>
                {person[property] && <Link target="_blank" data-interception="off" href={`tel:${person[property]}`}>{person[property]}</Link>}
            </Type>
        }
        case "linkedInProfile": {
            return <Type>
                {person[property] && <Link target="_blank" data-interception="off" href={person[property]}>{person[property]}</Link>}
            </Type>
        }
        case "location": {
            return <Type>{person[property].join(", ")}</Type>
        }
        case "workHistory": {
            return <Type><Stack tokens={{ childrenGap: 12 }}>
                {person[property].map((x, i) => {
                    return <Stack key={i}>
                        <Text styles={{ root: { fontWeight: 500 } }}>
                            <span dangerouslySetInnerHTML={{ __html: `${x.position} @ ${x.employer}` }} />
                        </Text>
                        <Text variant="medium">{formatDate(x.from)} - {formatDate(x.to) || "Present"}</Text>
                    </Stack>
                })}
            </Stack></Type>
        }
    }
}